import React, { useState, useMemo } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import ProfileMenuItem from './ProfileMenuItem'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Box,
  Hidden,
  Badge,
  Grid,
} from '@material-ui/core'
import { useAuth } from '../../hooks/context/AuthContext'
import HeaderLogo from './HeaderLogo'
import NavBar from './navbar/NavBar'
import * as Sentry from '@sentry/react'
import { enableBillingSite, enableSalesDashboard } from '../../../../shared/config/regional-configs'
import {
  CS_ROLE,
  HTA_DEALER_ROLE,
  HTS_DEALER_ROLE,
  DEALER_ROLE,
} from '../../../../server/constants/roles'
import { useGetBillingAccess } from '../../api/users'
import { HashLink } from 'react-router-hash-link'
import {
  ADMIN_PANEL,
  HTA_BMW,
  HTA_BYD,
  HTA_JLR,
  HTA_MB,
  HTA_VW,
} from '../../../../shared/constants/admin-permission'
import { MALAYSIA } from '../../../../shared/constants/countries-map'

const disallowedRolesBillingSite = [HTA_DEALER_ROLE, HTS_DEALER_ROLE]

const allowBackupApp = BACKUP_APP === 't'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
  },
  grow: {
    flexGrow: 1,
    height: '88px',
    [theme.breakpoints.down('sm')]: {
      height: '64px',
    },
  },
  menuButton: {
    //marginRight: theme.spacing(2),
  },
  title: {
    textDecoration: 'none',
    color: 'inherit',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbarMargin: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12),
    },
  },
  logoButton: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  logoImage: {
    height: 32,
    width: 32,
  },
  toolbar: {
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  largeIcon: {
    fontSize: '36px',
  },
}))

export const HeaderWrapper = ({ upperHeader, lowerHeader }) => {
  const classes = useStyles()
  return (
    <>
      <Grid container alignItems='center' direction='column'>
        <AppBar className={classes.appBar}>
          <Grid item md={12}>
            <Toolbar disableGutters={true} className={classes.toolbar}>
              {upperHeader}
            </Toolbar>
          </Grid>
          {lowerHeader}
        </AppBar>
      </Grid>
      <Toolbar className={classes.toolbarMargin} />
    </>
  )
}

const Header = ({ menuItems, authorized = true }) => {
  const { user, country, trialConfig } = authorized ? useAuth() : {}

  const location = useLocation()

  Sentry.setUser({ email: user?.email })
  Sentry.setTag('client', 'frontend')

  const classes = useStyles()

  const [accountAnchorEl, setAccountAnchorEl] = useState(null)

  const handleAccountMenu = (event) => {
    setAccountAnchorEl(event.currentTarget)
  }

  const handleAccountMenuClose = () => {
    setAccountAnchorEl(null)
  }

  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const handleMenu = (event) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const returnTo = () =>
    '?' +
    new URLSearchParams({
      return_to: `${location.pathname}${location.search}`,
    }).toString()

  const handleProfile = () => {
    window.location.href = `/profile${returnTo()}`
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const handleLogout = () => {
    window.location.href = `/logout${returnTo()}`
    // Sentry.configureScope((scope) => scope.setUser(null))
  }

  const handleLogoutMobile = () => {
    handleAccountMenuClose()
    handleLogout()
  }

  const { data: hasBillingAccess } = useGetBillingAccess({
    enabled: user?.accessConfig?.billing && enableBillingSite[country] && Boolean(user),
  })

  const enableBillingSiteLink =
    user?.accessConfig?.billing &&
    enableBillingSite[country] &&
    hasBillingAccess &&
    !disallowedRolesBillingSite.includes(user?.role)

  const enableSalesDealers = user?.isAdmin && enableSalesDashboard[country]

  const sharedMenuItems = useMemo(
    () => ({
      primary: [].filter(Boolean),
      secondary: [
        !allowBackupApp &&
          user?.isAdmin &&
          [ADMIN_PANEL, HTA_BMW, HTA_MB, HTA_JLR, HTA_VW, HTA_BYD].some((each) =>
            user?.adminPermission?.includes(each)
          ) && {
            label: 'Admin',
            to: '/admin',
            icon: <SettingsIcon />,
          },
        !allowBackupApp &&
          enableSalesDealers && {
            label: 'Sales Dashboard',
            to: '/sales',
            icon: <PeopleIcon />,
          },
        !allowBackupApp &&
          enableBillingSiteLink && {
            label: 'Billing',
            to:
              country === MALAYSIA
                ? 'https://billing.htgroup.com.my'
                : 'https://billing.hocktyre.com/',
            icon: <AccountBalanceOutlinedIcon />,
            external: 't', //it should be non boolean value to indicates it's external link
          },
      ].filter(Boolean),
    }),
    [user, enableBillingSiteLink]
  )

  const renderPrimaryMenu = (
    <Box display='flex' alignItems='center'>
      {[...sharedMenuItems.primary, ...(menuItems?.primary ?? [])].map(
        (item) =>
          item && (
            <Tooltip key={item.label} title={item.label} arrow>
              <IconButton
                aria-label={item.label}
                color='inherit'
                className={classes.menuButton}
                {...item}
                component={item.external ? 'a' : item.to ? RouterLink : undefined}
                href={item.external ? item.to : undefined}
                to={!item.external ? item.to : undefined}
                onClick={item.onClick}
              >
                {item.icon}
              </IconButton>
            </Tooltip>
          )
      )}
    </Box>
  )

  const renderSecondaryMenuDesktop = (sharedMenuItems?.secondary || menuItems?.secondary) && (
    <Box className={classes.sectionDesktop}>
      {[...sharedMenuItems.secondary, ...(menuItems?.secondary ?? [])].map(
        (item, idx) =>
          item && (
            <Box key={item.label + idx}>
              {item.icon ? (
                <Tooltip title={item.label} arrow>
                  <IconButton
                    aria-label={item.label}
                    color='inherit'
                    className={classes.menuButton}
                    {...item}
                    component={item.external ? 'a' : item.to ? RouterLink : undefined}
                    href={item.external ? item.to : undefined}
                    to={!item.external ? item.to : undefined}
                    onClick={item.onClick}
                  >
                    {item.icon}
                  </IconButton>
                </Tooltip>
              ) : (
                <Button
                  aria-label={item.label}
                  color='inherit'
                  className={classes.menuButton}
                  {...item}
                  component={item.external ? 'a' : item.to ? RouterLink : undefined}
                  href={item.external ? item.to : undefined}
                  to={!item.external ? item.to : undefined}
                  onClick={item.onClick}
                >
                  {item.label}
                </Button>
              )}
            </Box>
          )
      )}
    </Box>
  )

  const renderSecondaryMenuMobile = menuItems?.secondary &&
    (menuItems?.secondary.length > 0 || sharedMenuItems.secondary?.length > 0) && (
      <div className={classes.sectionMobile}>
        <IconButton
          aria-controls='main-menu'
          aria-haspopup='true'
          aria-label='main-menu'
          onClick={handleMenu}
          color='inherit'
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id='main-menu'
          anchorEl={menuAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          {[...sharedMenuItems.secondary, ...(menuItems?.secondary ?? [])].map(
            (item, idx) =>
              item && (
                <MenuItem
                  key={item.label + idx}
                  component={item.external ? 'a' : RouterLink}
                  href={item.external ? item.to : undefined}
                  to={!item.external ? item.to : undefined}
                  onClick={handleMenuClose}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
              )
          )}
        </Menu>
      </div>
    )

  const renderAccountMenu = user ? (
    <div>
      <IconButton
        aria-label='account'
        aria-controls='menu-account'
        aria-haspopup='true'
        onClick={handleAccountMenu}
        color='inherit'
      >
        <Badge
          overlap='circular'
          badgeContent='!'
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          color={trialConfig?.trialExpired ? 'error' : 'primary'}
          invisible={!trialConfig?.isTrial || !(trialConfig?.trialExpired || trialConfig?.warning)}
        >
          <PersonOutlineOutlinedIcon style={{ fontSize: '28px' }} />
        </Badge>
      </IconButton>

      <Menu
        id='menu-account'
        anchorEl={accountAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(accountAnchorEl)}
        onClose={handleAccountMenuClose}
      >
        <ProfileMenuItem onClick={handleProfile} />
        {user.role === DEALER_ROLE || user.role === HTS_DEALER_ROLE ? (
          <MenuItem component={RouterLink} to='/usersettings' onClick={handleAccountMenuClose}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText>User Settings</ListItemText>
          </MenuItem>
        ) : (
          ''
        )}

        <MenuItem onClick={handleLogoutMobile}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  ) : (
    <div>
      <Button component='a' href='/login'>
        Login
      </Button>
      <Button color='primary' variant='contained' component={HashLink} to='/signup#signup' smooth>
        Sign up
      </Button>
    </div>
  )

  return (
    <HeaderWrapper
      upperHeader={
        <>
          <RouterLink to='/' className={classes.logoButton} aria-label='home'>
            <HeaderLogo country={country} />
          </RouterLink>
          {!user ||
            (user.role === HTA_DEALER_ROLE && (
              <Hidden>
                <Typography
                  className={classes.title}
                  variant='h6'
                  noWrap
                  component={RouterLink}
                  to='/'
                >
                  Tyrehub
                </Typography>
              </Hidden>
            ))}
          <div className={classes.grow} />
          {renderSecondaryMenuDesktop}
          {renderPrimaryMenu}
          {renderAccountMenu}
          {renderSecondaryMenuMobile}
        </>
      }
      lowerHeader={
        <Hidden smDown>
          <Grid item md={12}>
            {user && ![CS_ROLE].includes(user?.role) && <NavBar />}
          </Grid>
        </Hidden>
      }
    />
  )
}

export default Header
