const { MALAYSIA, SINGAPORE } = require('./countries-map')
const { TYREHUB, PERFORMANCE, TRUCK, LUBRICANT, BRAKE, BATTERY, EKOTAYAR } = require('./companies')
const {
  PRODUCT_TYRE,
  PRODUCT_LUBRICANT,
  PRODUCT_BRAKE,
  PRODUCT_BATTERY,
} = require('./product-types')

const JOHOR = 'Johor'
const MELAKA = 'Melaka'
const PAHANG = 'Pahang'
const SELANGOR = 'Selangor'
const PENANG = 'Penang'
const IPOH = 'Ipoh'

const TAMPOI = 'Tampoi'
const PASIR_GUDANG = 'Pasir Gudang'
const PUCHONG = 'Puchong'
const GELANG_PETAH = 'Gelang Patah'
const KUANTAN = 'Kuantan'

const T = 'Tuas 28'
const U = 'Tuas 21'
const A = 'Ang Mo Kio'
const K = 'Kaki Bukit'
const S = 'Sin Ming'
const W = 'Woodlands'
const HV = 'Havelock'
const BT = 'Upp Bukit Timah'
const JUR = 'Jurong'
const TG = 'Toh Guan'

const Main = 'Main'
const Satellite = 'Satellite'

const visibleSitesOptions = {
  [MALAYSIA]: {
    default: null,
    // Sites start with Z are virtual warehouses, only for hiding tyres
    [TYREHUB]: ['H1', 'H2', 'H3', 'H5', 'H6', 'H7', 'Z1', 'Z2', 'Z3', 'Z5', 'Z6', 'Z7'],
    hta: ['H1', 'H5'],
    [PERFORMANCE]: ['P1', 'P2', 'P3', 'P5'],
    [TRUCK]: ['T1', 'T2', 'T3', 'T4', 'T5', 'T6'],
    [EKOTAYAR]: ['K1', 'K2', 'K3', 'K5'],
    [LUBRICANT]: ['L1', 'L2', 'L3'],
    [BRAKE]: ['B1', 'B5'],
    [BATTERY]: ['E1', 'E2', 'E3', 'E5'],
  },
  [SINGAPORE]: {
    default: ['T', 'U', 'A', 'K', 'S', 'W', 'HV', 'BT', 'JUR', 'TG'],
  },
}

const visibleSitesSalesOptions = {
  [MALAYSIA]: {
    [PRODUCT_TYRE]: [
      'H1',
      'H2',
      'H3',
      'H5',
      'H6',
      'H7',
      'P1',
      'P2',
      'P3',
      'P5',
      'T1',
      'T2',
      'T3',
      'T4',
      'T5',
      'T6',
      'Z1',
      'Z2',
      'Z3',
      'Z5',
      'Z6',
      'Z7',
      'K1',
      'K2',
      'K3',
      'K5',
    ],
    [PRODUCT_LUBRICANT]: ['L1', 'L2', 'L3'],
    [PRODUCT_BRAKE]: ['B1', 'B5'],
    [PRODUCT_BATTERY]: ['E1', 'E2', 'E3', 'E5'],
  },
  [SINGAPORE]: {
    [PRODUCT_TYRE]: ['T', 'U', 'A', 'K', 'S', 'W', 'HV', 'BT', 'JUR', 'TG'],
    [PRODUCT_BATTERY]: ['T', 'U', 'A', 'K', 'S', 'W', 'HV', 'BT', 'JUR', 'TG'],
  },
}

const SITES_MAP_MULTI = {
  [MALAYSIA]: {
    sitesDict: {
      [TYREHUB]: {
        H1: JOHOR,
        H2: MELAKA,
        H3: PAHANG,
        H5: SELANGOR,
        H6: PENANG,
        H7: IPOH,
        // Virtual
        Z1: JOHOR,
        Z2: MELAKA,
        Z3: PAHANG,
        Z5: SELANGOR,
        Z6: PENANG,
        Z7: IPOH,
      },
      hta: {
        H1: JOHOR,
        H2: MELAKA,
        H3: PAHANG,
        H5: SELANGOR,
        H6: PENANG,
        H7: IPOH,
      },
      [PERFORMANCE]: {
        P1: JOHOR,
        P2: MELAKA,
        P3: PAHANG,
        P5: SELANGOR,
      },
      [TRUCK]: {
        T1: TAMPOI,
        T2: PASIR_GUDANG,
        T3: PUCHONG,
        T4: MELAKA,
        T5: GELANG_PETAH,
        T6: KUANTAN,
      },
      [EKOTAYAR]: {
        K1: JOHOR,
        K2: MELAKA,
        K3: PAHANG,
        K5: SELANGOR,
      },
      [LUBRICANT]: {
        L1: JOHOR,
        L2: MELAKA,
        L3: KUANTAN,
      },
      [BRAKE]: {
        B1: JOHOR,
        B5: PUCHONG,
      },
      [BATTERY]: {
        E1: JOHOR,
        E2: MELAKA,
        E3: PAHANG,
        E5: SELANGOR,
      },
    },
    statesDict: {
      [TYREHUB]: {
        JH: 'H1',
        ME: 'H2',
        PH: 'H3',
        SL: 'H5',
        PG: 'H6',
        IP: 'H7',
      },
      hta: {
        JH: 'H1',
        ME: 'H2',
        PH: 'H3',
        SL: 'H5',
        PG: 'H6',
        IP: 'H7',
      },
      [PERFORMANCE]: {
        JH: 'P1',
        ME: 'P2',
        PH: 'P3',
        SL: 'P5',
      },
      [TRUCK]: {},
      [EKOTAYAR]: {
        JH: 'K1',
        ME: 'K2',
        PH: 'K3',
        SL: 'K5',
      },
      [LUBRICANT]: {
        JH: 'L1',
        ME: 'L2',
        PH: 'L3',
      },
      [BRAKE]: {
        JH: 'B1',
        SL: 'B5',
      },
      [BATTERY]: {
        JH: 'E1',
        ME: 'E2',
        PH: 'E3',
        SL: 'E5',
      },
    },
  },
  [SINGAPORE]: {
    sitesDict: {
      default: {
        T,
        U,
        A,
        K,
        S,
        W,
        HV,
        BT,
        JUR,
        TG,
      },
    },
    statesDict: {
      default: {
        T: 'T',
        U: 'U',
        A: 'A',
        K: 'K',
        S: 'S',
        W: 'W',
        HV: 'HV',
        BT: 'BT',
        JUR: 'JUR',
        TG: 'TG',
      },
    },
    mergedSitesDict: {
      default: {
        [Main]: { T, U },
        [Satellite]: { A, K, S, W, HV, BT, JUR, TG },
      },
    },
  },
}

const SITES_MAP = {
  [MALAYSIA]: {
    sitesDict: {
      H1: JOHOR,
      H2: MELAKA,
      H3: PAHANG,
      H5: SELANGOR,
      H6: PENANG,
      H7: IPOH,
      P1: JOHOR,
      P2: MELAKA,
      P3: PAHANG,
      P5: SELANGOR,
      T1: TAMPOI,
      T2: PASIR_GUDANG,
      T3: PUCHONG,
      T4: MELAKA,
      T5: GELANG_PETAH,
      T6: KUANTAN,
      L1: JOHOR,
      L2: MELAKA,
      L3: KUANTAN,
      B1: JOHOR,
      B5: PUCHONG,
      E1: JOHOR,
      E2: MELAKA,
      E3: PAHANG,
      E5: SELANGOR,
      K1: JOHOR,
      K2: MELAKA,
      K3: PAHANG,
      K5: SELANGOR,
      // Virtual
      Z1: JOHOR,
      Z2: MELAKA,
      Z3: PAHANG,
      Z5: SELANGOR,
      Z6: PENANG,
      Z7: IPOH,
    },
    statesDict: {
      JH: 'H1',
      ME: 'H2',
      PH: 'H3',
      SL: 'H5',
      PG: 'H6',
      IP: 'H7',
    },
  },
  [SINGAPORE]: {
    sitesDict: {
      T,
      U,
      A,
      K,
      S,
      W,
      HV,
      BT,
      JUR,
      TG,
    },
    statesDict: {
      T: 'T',
      U: 'U',
      A: 'A',
      K: 'K',
      S: 'S',
      W: 'W',
      HV: 'HV',
      BT: 'BT',
      JUR: 'JUR',
      TG: 'TG',
    },
    mergedSitesDict: {
      [Main]: { T, U },
      [Satellite]: { A, K, S, W, HV, BT, JUR, TG },
    },
  },
}

const MY_SITES_CODE = {
  H: TYREHUB,
  Z: TYREHUB,
  P: PERFORMANCE,
  T: TRUCK,
  L: LUBRICANT,
  B: BRAKE,
  E: BATTERY,
  K: EKOTAYAR,
}

const SITES_DELIVERY_CONFIG = {
  [MALAYSIA]: {
    [PRODUCT_TYRE]: {
      H1: JOHOR,
      H2: MELAKA,
      H3: PAHANG,
      H5: SELANGOR,
      H6: PENANG,
      H7: IPOH,
    },
    [PRODUCT_LUBRICANT]: {
      L1: JOHOR,
      L2: MELAKA,
      L3: KUANTAN,
    },
    [PRODUCT_BRAKE]: {
      B1: JOHOR,
      B5: PUCHONG,
    },
    [PRODUCT_BATTERY]: {
      E1: JOHOR,
      E2: MELAKA,
      E3: PAHANG,
      E5: SELANGOR,
    },
  },
}

const sitesDict = [
  {
    label: JOHOR,
    value: 'JH',
  },
  {
    label: MELAKA,
    value: 'ME',
  },
  {
    label: PAHANG,
    value: 'PH',
  },
  {
    label: SELANGOR,
    value: 'SL',
  },
  {
    label: PENANG,
    value: 'PG',
  },
  {
    label: IPOH,
    value: 'IP',
  },
]

const EKT_SITES = visibleSitesOptions[MALAYSIA][EKOTAYAR]

module.exports = {
  SITES_MAP,
  SITES_MAP_MULTI,
  Main,
  Satellite,
  MY_SITES_CODE,
  visibleSitesOptions,
  visibleSitesSalesOptions,
  SITES_DELIVERY_CONFIG,
  sitesDict,
  EKT_SITES,
}
